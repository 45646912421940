* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Message Styles */

.message {
    margin-bottom: 16px;
    width: 100%;
}

.message__name {
    font-weight: 600;
    font-size: 14px;
    margin-right: 8px;
}
.rdw-editor-main {
    border: 1px solid #d9d9d9;
    min-height: 300px;
}
.message__meta {
    color: #777;
    font-size: 14px;
}

.message a {
    color: #0070cc;
}
.chat__sidebar {
    padding: 10px;
    display: inline-block;
    width: 100%;
    height: 80%;
    color: white;
    /* background: #999; */
    overflow-y: auto;
}
.DIV {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: max-content;
    padding: 10px;
    width: 100%;
    overflow: hidden;
    border-bottom: 1.5px solid #eee;
}
.DIV:hover {
    background: #e4e4e485;
}
.heading-2 {
    padding: 15px;
    /* height: 10vh; */
    font-size: 20px;
    letter-spacing: 2px;
    color: #222;
    text-transform: capitalize;
    background-color: #e4e4e485;
    margin-top: 0;
}
.main {
    display: flex;
    height: calc(100vh - 100px);
}

.video {
    flex: 0 0 70%;
    display: flex;
    background-color: aliceblue;
}

.sidebar {
    flex: 0 0 30%;
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
}
.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10%;
    background-color: #eee;
}
.guest {
    width: 100%;
    height: 47%;
}
.speaker {
    height: 47%;
    margin-top: 10px;
}

#style-5::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar-thumb {
    background-color: #0ae;

    background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        color-stop(0.5, rgba(255, 255, 255, 0.2)),
        color-stop(0.5, transparent),
        to(transparent)
    );
}
.ant-col-24,
.ant-col-18,
.ant-col-20 {
    /*max-height: 200px;*/
    flex: 0 0 auto !important;
}
.ant-col {
    max-height: 100% !important;
}

.ant-select-arrow {
    right: '0px';
    left: 10px;
    color: #222;
}
.ant-select-selection-item {
    left: 20px;
}
.ant-select-selection-placeholder {
    margin-left: 20px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 30px;
}
.ant-select-selector {
    border-radius: 8px !important;
}

.title-status {
    margin: 0% 2% 0% 2%;
}

.columnCards {
    max-width: 17%;
    min-height: 13vh;
    background: #fff;
    margin: 10px;
    border-radius: 8px;
    border: 1px solid #74f7ae;
    cursor: pointer;
}

.columnCards:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.columnCards .active {
    background-color: #e4e4e485;
}

.active {
    border: 3px solid #74f7ae;
}

.statusCount {
    background: #ededed;
    padding: 5px;
    border-radius: 8px;
    margin: 10px 0px 10px 0px;
}
.city_reset_headers{
    display: flex;
    justify-content: space-between;
}

.searchFilters {
    background: #ededed;
    padding: 15px;
    border-radius: 8px;
    margin: 10px 0px 20px 0px;
    /* min-height: 40vh; */
    box-shadow: 2px 1px #e2e7f1;
    border: 0.1px solid #e2e7f1;
    justify-content: right;
}

.searchCards {
    /* height: 18vh; */
    padding: 5px;
    background: #fff;
    margin: 10px 0px 0px 0px;
    border-radius: 8px;
    border: 1px solid #74f7ae;
}

.searchBtn {
    background: #f6f6f7;
    /* max-width: 60vh; */
    max-height: 8vh;
    border-radius: 8px;
    border: 2px solid #d9d9d9;
    margin: 5px 8px 6px 8px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
}
.search-remove {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
}

.resetFilter {
    margin: 12px ;
    color: red;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
}

.save-reset-btn {
    margin: 4px;
    border-radius: 8px;
}
.width-fit-contact {
    min-width: fit-content;
    /* margin-right: 10px; */
}

.underline-on-hover {
 color: #0080FF;
 cursor: pointer;
}

.underline-on-hover:hover {
    text-decoration: underline;
}