// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  //padding-bottom: 60px;
  overflow-x: hidden;
  padding: 0px;
}